import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  background: #f2f2f2;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    padding: 40px 5px;
  }
`;
const StyledTitle = styled.h2`
  text-align: center;
  font-style: normal;
  font-weight: bold;
  color: #1e1e1e;
  margin-bottom: 30px;
`;
const VideoContainer = styled.div`
  width: 632px;
  height: 360px;
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    max-width: 500px;
    max-height: 281px;
    width: 100%;
  }
`;
const StyledIFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border-style: none;
`;
const StyledDescription = styled.p`
  width: 632px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 24px 0;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    font-size: 14px;
    line-height: 20px;
    width: 90%;
  }
`;
const Explainer = () => {
  const queryData = useStaticQuery(graphql`
    query ExplainerDataQuery {
      contentfulComponentsPage(pageId: { eq: "Nimble Anytime Landing" }) {
        components {
          ... on ContentfulNimbleSection {
            __typename
            id
            sectionId
            title
            description {
              id
              description
            }
            components {
              ... on ContentfulLink {
                id
                linkText
                linkUrl
              }
            }
          }
        }
      }
    }
  `);
  const {
    title,
    description: { description },
    components
  } = queryData.contentfulComponentsPage.components
    .filter(data => data.__typename === 'ContentfulNimbleSection')
    .filter(data => data.sectionId === 'Explainer Video Section')[0];
  const { linkText, linkUrl } = components[0];
  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <VideoContainer>
        <StyledIFrame
          className="responsive-iframe"
          title={linkText}
          src={linkUrl}
        />
      </VideoContainer>
      <StyledDescription>{description}</StyledDescription>
    </StyledContainer>
  );
};

export default Explainer;
