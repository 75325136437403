/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Banner from './components/banner.component';
import RepaymentCalculatorComplex from './components/repaymentCalculatorComplex';
import Explainer from './components/explainer.component';
import ApplyInfo from './components/applyInfo.component';
import AppleApy from './components/applePay.component';
import TestimonialsComponent from '../../../components/testimonials';
import FAQComponent from '../../../components/faq';
import EndOfPage from '../components/endOfPage/endOfPage.component';

const NimbleAnytime = ({ componentData }) => {
  const [applyLink, setApplyLink] = useState(null);

  const bannerData = componentData.filter(
    item => item.__typename === 'ContentfulNimblePageHeadingSection'
  )[0];
  const testimonialsData = componentData.filter(
    item => item.__typename === 'ContentfulComponentTestimonials'
  )[0];
  const faqsData = componentData.filter(
    item => item.__typename === 'ContentfulComponentFaq'
  )[0];
  const calculatorComplexData = componentData.filter(
    item => item.__typename === 'ContentfulNimbleRepaymentCalculatorComplex'
  )[0];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmId = urlParams.get('utm_medium');
    if (utmId && bannerData.applyNowLink) {
      setApplyLink(`${bannerData.applyNowLink}?utm_medium=${utmId}`);
    } else if (bannerData.applyNowLink) {
      setApplyLink(`${bannerData.applyNowLink}`);
    }
  }, []);

  return (
    <>
      <Banner bannerData={bannerData} applyLink={applyLink} />
      {calculatorComplexData && (
        <RepaymentCalculatorComplex data={calculatorComplexData}  trackingApplyLink={applyLink} />
      )}
      <Explainer />
      <ApplyInfo />
      <AppleApy />
      {testimonialsData && <TestimonialsComponent data={testimonialsData} />}
      {faqsData && <FAQComponent data={faqsData} />}
      <EndOfPage applyName="Nimble Anytime" applyLink={applyLink} />
    </>
  );
};

export default NimbleAnytime;
