import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import LinkButton from '../../../../elements/linkButton/linkButton.component';
import NimbleLogo from '../../../../../static/images/Nimble-logo-black.svg';
import ApplePayLogo from '../../../../../static/images/Apple-Pay-logo.svg';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 5px;
  background: #f2f2f2;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
  }
`;
const TitleContainer = styled.div`
  display: flex;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    flex-direction: column;
  }
`;
const StyledTitle = styled.h2`
  font-style: normal;
  text-align: center;
  color: #1e1e1e;
  margin: 0;
`;
const TextRed = styled(StyledTitle)`
  white-space: pre;
  display: inline-block;
  color: ${props => props.theme.colours.nimbleRed};
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 64px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    flex-direction: column;
    margin-top: 24px;
  }
`;
const IphoneImage = styled.img`
  width: 392px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    width: 241px;
  }
`;
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 416px;
  margin-right: 108px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    margin: 0;
  }
`;
const StyledDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 24px 0;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    font-size: 14px;
    line-height: 20px;
    max-width: 500px;
    width: 100%;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  padding: 30px 0;
`;
const LogoImage = styled.img`
  padding: 0 16px;
  border-right: 1px solid #bdbdbd;
`;
const ApplePayImage = styled.img`
  padding: 0 16px;
`;
const CompatibleDevice = styled.div`
  text-align: center;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    display: none;
  }
`;
const CompatibleDeviceMobile = styled.div`
  display: none;
  margin-top: 24px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    display: block;
  }
`;
const CompatibleDeviceText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
`;
const StyledLink = styled.a`
  text-decoration: underline;
  color: #4f4f4f;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;
const ApplePay = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulComponentsPage(pageId: { eq: "Nimble Anytime Landing" }) {
        components {
          ... on ContentfulNimbleSection {
            id
            sectionId
            title
            subtitle
            description {
              description
            }
            components {
              ... on ContentfulLink {
                id
                linkText
                linkUrl
                linkId
              }
            }
            sectionImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `).contentfulComponentsPage.components.filter(
    item => item.sectionId === 'Anytime Apply Pay'
  )[0];
  const {
    title,
    subtitle,
    description: { description },
    sectionImage: {
      file: { url: sectionImageUrl }
    },
    components
  } = data;
  const { linkText: findOutMoreText, linkUrl: findOutMoreUrl } = components.filter(item => item.linkId === 'Find Out More')[0];
  const { linkText: cDeviceText, linkUrl: cDeviceUrl } = components.filter(item => item.linkId === 'Compatible Apple Devices')[0];
  return (
    <StyledContainer>
      <TitleContainer>
        <StyledTitle>{title}</StyledTitle>
        <TextRed>{subtitle}</TextRed>
      </TitleContainer>
      <ContentContainer>
        <Contents>
          <StyledDescription>{description}</StyledDescription>
          <LinkButton
            text={findOutMoreText}
            color="RED"
            link={findOutMoreUrl}
          />
          <LogoContainer>
            <LogoImage src={NimbleLogo} alt="nimble logo" />
            <ApplePayImage src={ApplePayLogo} alt="Apply Pay Logo" />
          </LogoContainer>
          <CompatibleDevice>
            <CompatibleDeviceText>
              *For a list of compatible Apple Pay devices, see
            </CompatibleDeviceText>
            <StyledLink href={cDeviceUrl}>
              {cDeviceText}
            </StyledLink>
          </CompatibleDevice>
        </Contents>
        <IphoneImage src={sectionImageUrl} alt="iphone iwatch" />
        <CompatibleDeviceMobile>
          <CompatibleDeviceText>
            *For a list of compatible Apple Pay devices, see
          </CompatibleDeviceText>
          <StyledLink href={cDeviceUrl}>
            {cDeviceText}
          </StyledLink>
        </CompatibleDeviceMobile>
      </ContentContainer>
    </StyledContainer>
  );
};

export default ApplePay;
